.page-container {
    position: relative;
    overflow-y: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    /* Primary/ Gradient */
    background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%), #dadada;
    color: white;
}

.page-header-onboarding {
    margin-bottom: 30px;
    width: 100%;
    /* border: 1px solid #000000; */
    height: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
}

.page-header-image {
    /* left */

    /* Auto layout */
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    left: 0;
    width: 246px;
    height: 70px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.progress-bar-container {
    display: flex;
    flex-direction: column;
    width: 400px;
}

/* change all inputs of type checkbox (all modes) under .page-container  to have 0 border-radius*/
.page-container input[type="checkbox"] {
    border-radius: 0;
}

.page-container input[type="checkbox"]::before {
    border-radius: 0;
}

.page-header-progress {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.page-header-progress-bar {
    width: 400px;
}

.form-container-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px 0px;
    z-index: 10000;
    width: 80%;
    height: 725px;
    /* border: 1px solid #000000; */

    /* Inside auto layout */
}

.previous-page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    pointer-events: none;
    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
    opacity: 0;
    transform: translateY(-100vh);
}

.current-page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    transition: transform 300ms ease 0s, opacity 200ms ease 0s;
    display: flex;
}

.next-page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    pointer-events: none;
    transition: transform 300ms ease 0s, opacity 200ms ease 0s;
    opacity: 0;
    transform: translateY(100vh);
}

.layout-wrapper {
    position: absolute;
    inset: 0px;
    opacity: 0;
    transition: opacity 0.2s ease 0s;
    pointer-events: none;
    z-index: -10;
}

.input-header {
    /* Auto layout */

    /* padding: 0px 248px; */
    gap: 10px;
    word-wrap: break-word;
    /*     width: 100%; */
    /* height: 50px; */

    /* Inside auto layout */
}

.input-header-text {
    /* Menu Header */
    /* height: 50px; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* White */

    color: #ffffff;

    /* Inside auto layout */
}

.input-header-text > p {
    margin-bottom: 0;
}

.input-caption-container {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    /*   width: 1280px; */

    /* Inside auto layout */

    flex: none;
    /*  order: 1; */
    flex-grow: 0;
}

.input-caption-text {
    width: 425px;
    height: 42px;

    /* Samplead body */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.input-box-text {
    /* Input */
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 10px;
    gap: 10px;
    width: 481px;
    height: 36px;
    left: 399.5px;
    top: 170px;
    background: #ffffff;
    color: #000000;
    /* Naturals / N200 */

    /* border: 1px solid #000000; */
    border-radius: 6px;
}

.input-box-text::placeholder {
    color: #dadada;
}

.input-bar {
}

.short-input-box {
    /* Input */
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 10px;
    gap: 10px;

    height: 36px;
    left: 399.5px;
    top: 170px;

    background: #ffffff;
    /* Naturals / N200 */

    /* border: 1px solid #000000; */
    border-radius: 6px;
}

.error-msg {
    /* Error Message */
    color: white;
}

.checkbox-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.checkbox-container-wrapper {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
}

.checkbox-container {
    /* Auto layout */
    /*  margin-left: 8%; */
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
    width: 1280px;
    justify-content: center;
    /* height: 101px;
    height: auto; */
    width: 500px;
    /* Inside auto layout */
    flex-wrap: wrap;
}

.checkbox-input {
    height: 21px;
    left: 22px;
    top: 30px;
    width: 25%;
    flex-wrap: wrap;
    /* Samplead body */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    columns: 2 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /*  margin-left: 40px; */
    /*  padding-left: 30px; */
    /* White */

    color: #ffffff;
}

.checkbox-col-3 {
    width: 31%;
    columns: 3 auto;
}

.checkbox-col-4 {
    width: 20%;
}

.checkbox-w-close {
    justify-content: initial;
    width: 45%;
}

.checkbox-w-medium {
    min-width: 50%;
    max-width: 100%;
    margin-left: 0;
}

.checkbox-w-wide {
    width: 1280px !important;
}

.checkbox-input-inner-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
}

.subheader {
    width: 460px;
    height: 42px;
    /* Samplead body */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /*  margin-top: 20px; */
    margin-bottom: 20px;

    color: #ffffff;
}

.margin-y-low {
    margin-top: 5px;
    margin-bottom: 5px;
}

.fit-to-width {
    width: 100%;
}

.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.centered-radio {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: center;
    margin-top: 10px;
    margin-left: 80px;
}

.mb-1 {
    margin-bottom: 10px;
}

.bottom-question-container {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 29px;
    justify-content: center;
    width: 100%;
    height: 200px;
    /* Inside auto layout */
    margin-top: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.full-length-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.radio-and-input-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: auto;
}

.auto-grow {
    height: auto;
}

.align-center {
    text-align: center;
}

.add-custom-option {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
}

.add-custom-option-text {
    cursor: pointer;
}

.add-custom-option-container {
    margin-top: 80px;
}

.link {
    cursor: pointer;
}

.link:hover {
    color: #ffc700;
}

.low-padding {
    padding: 20px;
}

.file-uploader-box {
    display: flex;
    color: white !important;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px;
    gap: 18px;
    color: white;
    /*  Add dotted white border */
    border-color: white !important;
    width: 543px;
    height: 100px !important;
}

.file-uploader-box > svg {
    display: none;
}

.file-uploader-box > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px;
}

.file-uploader-box > div > span {
    color: white !important;
    font-size: 15px;
}

.file-uploader-box > div > span:last-child {
    display: none;
}

.file-upload-box-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
}

.file-upload-box-child-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.uploaded-file-list-container {
    padding: 10px;
    color: white;
}

.subheader-text {
    width: 480px;
    text-align: center;
}

.weight-200 {
    font-weight: 200;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}

.autocomplete_wrapper {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1300px) {
    .checkbox-container {
        margin-left: 0;
        width: 100% !important;
        height: auto;
    }

    .checkbox-w-close {
        width: 100%;
    }

    .checkbox-col-4 {
        width: 31%;
    }
}

/* media query */
@media (max-width: 768px) {
    .page-container {
        min-height: 100vh;
        height: 100%;
    }

    .form-container {
        height: auto;
    }

    .checkbox-container {
        margin-left: 0;
        width: 100%;
        height: auto;
    }

    .checkbox-container-wrapper {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .checkbox-input {
        width: 48%;
        margin-top: 15px;
    }

    .bottom-question-container {
        flex-direction: column;
        height: auto;
    }

    /* .radio-and-input-container {} */

    .left-question {
        margin-bottom: 80px;
    }
}
