.progress-container {
    width: 100%;
    min-width: 600px;
    height: 20px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-image: linear-gradient(45deg, #de4daa, #f6d327);
    transition: width 0.1s linear;
}

@keyframes confetti {
    0% {
        opacity: 1;
        transform: translateY(0%) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateY(100%) rotate(720deg);
    }
}

.confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
}

.confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #4caf50;
    opacity: 0;
    animation: confetti 1s ease-in-out forwards;
}

.confetti:nth-child(even) {
    background-color: #8bc34a;
}

.confetti:nth-child(3n) {
    background-color: #ffeb3b;
}

.confetti:nth-child(5n) {
    background-color: #f44336;
}

.confetti:nth-child(7n) {
    background-color: #2196f3;
}

.confetti:nth-child(11n) {
    background-color: #9c27b0;
}

.confetti:nth-child(13n) {
    background-color: #009688;
}

.confetti:nth-child(17n) {
    background-color: #ffc107;
}

.confetti:nth-child(19n) {
    background-color: #795548;
}

.confetti:nth-child(23n) {
    background-color: #3f51b5;
}

.confetti:nth-child(29n) {
    background-color: #e91e63;
}

.confetti:nth-child(31n) {
    background-color: #00bcd4;
}

.confetti:nth-child(37n) {
    background-color: #cddc39;
}

.confetti:nth-child(41n) {
    background-color: #4caf50;
}

.confetti:nth-child(n) {
    animation-delay: calc(var(--animation-duration) * (var(--i) / 50));
}

@keyframes move {
    100% {
        transform: translateY(calc(100% + 10px)) rotate(720deg);
    }
}
