.activity-icon {
    width: 37.25px;
    height: 40px;
}
.activity-icon.event {
    background: url("assets/images/spritesheet.png") no-repeat -120px -20px;
}
.activity-icon.group {
    background: url("assets/images/spritesheet.png") no-repeat -120px -80px;
}
.activity-icon.post {
    background: url("assets/images/spritesheet.png") no-repeat -120px -140px;
}

.icon {
    width: 48px;
    height: 48px;
}
.icon.auto-discovery {
    background: url("assets/images/icons/magic_wand_icon.png") no-repeat;
}
.icon.message-typing {
    background: url("assets/images/spritesheet.png") no-repeat -120px -260px;
}
.icon.export {
    background: url("assets/images/spritesheet.png") no-repeat -120px -320px;
}
.icon.manual {
    background: url("assets/images/spritesheet.png") no-repeat -120px -380px;
}
.icon.empty {
    background: url("assets/images/spritesheet.png") no-repeat -120px -440px;
}
.icon.hot {
    width: 35px;
    height: 57px;
    background: url("assets/images/spritesheet.png") -20px -320px;
}
.icon.close {
    width: 10px;
    height: 10px;
    background: url("assets/images/spritesheet.png") no-repeat -60px -240px;
}
.icon.empty-stats {
    background: url("assets/images/spritesheet.png") no-repeat -120px -500px;
    width: 50px;
    height: 51px;
}
.icon.life-server-ring {
    background: url("assets/images/spritesheet.png") no-repeat -200px -220px;
    width: 53px;
    height: 53px;
}
.icon.sequence {
    background: url("assets/images/sequence_icon.png") no-repeat;
    width: 53px;
    height: 53px;
}
.icon.linkedin {
    background: url("assets/images/linkedin.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.linkedin:hover {
    background: url("assets/images/linkedin_hover.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.linkedin_active {
    background: url("assets/images/linkedin_active.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.email {
    background: url("assets/images/email.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.email:hover {
    background: url("assets/images/email_hover.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.email_active {
    background: url("assets/images/email_active.png") no-repeat;
    width: 24px;
    height: 24px;
}
.icon.up_arrow {
    background: url("assets/images/up_arrow.png") no-repeat;
    width: 18px;
    height: 18px;
}
.icon.down_arrow {
    background: url("assets/images/down_arrow.png") no-repeat;
    width: 18px;
    height: 18px;
}
.icon.samplead_icon {
    background: url("assets/images/samplead_icon.png") no-repeat;
    width: 23px;
    height: 38px;
}
.icon.edit {
    background: url("assets/images/edit.png") no-repeat;
    width: 20px;
    height: 20px;
}

