@use "styles/base";

.progress-bar-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;

    & > .progress-bar-container {
        height: 10px;
        width: 100%;
        background-color: base.$color-N200;
        border-radius: 50px;
        //margin: 50px;

        & > .progress-bar-filler {
            height: 100%;
            background-color: base.$color-P300;
            border-radius: inherit;
            text-align: right;
            transition: width 0.3s ease-in-out;

            & > .progress-bar-label {
                padding: 5px;
                color: white;
                font-weight: bold;
            }
        }
    }
}
