.custom-date-range {
    position: absolute;
    z-index: 100;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    .back-icon {
        margin-bottom: 10px;
        :hover {
            background: rgba(222, 220, 220, 0.834);
            border-radius: 50%;
            cursor: pointer;
        }
    }
    background-color: #fff;
}
