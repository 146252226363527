.suggestions-list-wrapper {
    position: absolute;
    top: -51vh;
    right: -45vw;
    width: 40vw;
    min-height: 93vh;
    height: fit-content;
    background: #f2f2ff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    padding-bottom: 10vh;
}
.suggestions-list-wrapper > .suggestions-list-header {
    position: sticky;
    top: 0;
    padding: 22px;
    color: black;
    min-height: 5vh;
    background: #f2f2ff;
    z-index: 9990;
}
.suggestions-list-wrapper > .suggestions-list-header > .suggestions-list-header-close {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
}
.suggestions-list-wrapper > .suggestions-list-header > .suggestions-list-header-close > .close {
    cursor: pointer;
}
.suggestions-list-wrapper > .suggestions-list-header > .suggestions-list-header-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.suggestions-list-wrapper > .suggestions-list-header > .suggestions-list-header-title span:nth-child(2) {
    color: rgba(122, 122, 122, 0.5);
}
.suggestions-list-wrapper > .suggestions-list__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;
}
.suggestions-list-wrapper > .suggestions-list__content > div.no-suggestions {
    width: 362px;
    height: 132px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.suggestions-list-wrapper > .suggestions-list__content > div.no-suggestions > p.samplead-menu-header {
    color: black;
}
.suggestions-list-wrapper > .suggestions-list__content > div.no-suggestions > p.samplead-body {
    color: #535353;
}

.filter-suggestions {
    /* margin-bottom: 32px; */
}
.filter-suggestions > select {
    border: 1px solid #dadada;
    border-radius: 4px;
    width: 100px;
    height: 40px;
    margin: 5px;
}

