div.editable-text {
    padding: 5px;
    width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    color: #353535;
}
div.editable-text > input {
    width: 100%;
    outline: none;
    border: none;
}

