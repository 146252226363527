@import "styles/base";

.lead-row-container {
    width: 100%;

    & p {
        color: $color-N500;
    }

    & > .lead-row-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        /** top right bottom left */
        padding: 6px 20px 6px 26px;

        & > .unread > * {
            font-weight: bold;
            color: black;
        }

        & > .lead-row-name {
            //& > * {
            //  outline: 1px solid #07c3d7; //TODO: outline-test;
            //}

            width: 222px;
            display: flex;
            align-items: center;
            gap: $gap-10;

            & > img {
                width: 30px;
                height: 30px;
                background: $color-turquoise300;
                border: 1px solid $color-N100;
                border-radius: 50%;
            }

            & > .lead-row-name--text {
                width: 166px;

                & > .lead-row-name--text-inner {
                    width: 100%;
                }
            }
        }

        & > .lead-row-title,
        > .lead-row-mail {
            width: 251.89px;

            .lead-row-title--text {
                width: 500px;
            }

            .lead-row-mail--text {
                width: 189px;
            }
        }

        & > .lead-row--hot-indicator {
            position: relative;
            width: 73px;
            height: 26px;

            & .lead-row--hot-indicator-icon {
                position: absolute;
                top: -16px;
                left: -8px;
                width: 35px;
                height: 57px;
                background: url("assets/images/spritesheet.png") no-repeat -20px -320px;
                transform: scale(0.45);
            }
        }

        & > .lead-row-buttons {
            display: flex;
            width: 250px;
            height: 36px;
            justify-content: flex-end;

            & .lead-row-buttons-remove {
                .button {
                    border-color: transparent;
                }
            }

            & .lead-row-buttons-message {
                .button {
                    padding: 8px 11px;
                    border-color: transparent;
                }

                & .lead-row-buttons-message--icon-expand {
                    width: 20px;
                    height: 20px;
                    background: url("assets/images/spritesheet.png") no-repeat -200px -180px;
                }

                & .lead-row-buttons-message--icon-collapse {
                    width: 20px;
                    height: 20px;
                    background: url("assets/images/spritesheet.png") no-repeat -240px -180px;
                }
            }
        }
    }

    & > .lead-row-bottom-wrapper {
        padding: 20px;

        & > .lead-row-bottom {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            gap: 32px;

            & .samplead-body,
            .samplead-body--bold {
                color: $color-N500;
            }

            & > .lead-row-bottom__left,
            & > .lead-row-bottom__right {
                display: flex;
                flex-direction: column;
                row-gap: $gap-20;
            }

            & .lead-row-bottom--content {
                display: flex;
                flex-direction: column;
                row-gap: 4px;
            }

            & > .lead-row-bottom__left {
                width: 610px;

                & > footer {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                }
            }

            & > .lead-row-bottom__right {
                width: 502px;
                height: 312px;
                justify-content: space-between;

                & .samplead-body.lrbc--message {
                    width: 491px;
                    padding: 10px;
                    border: 1px solid $color-N300;
                    border-radius: $radius-6;
                }

                & > .lead-row-bottom__right--tags {
                    height: 107px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: $gap-10;

                    & > .lead-row-bottom__right--badges {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        gap: 6px;
                    }
                }

                & > footer {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }

    & > .lead-row-message--divider {
        width: 100%;
        height: 1px;

        background: $color-N100;
        margin: 6px 0;
    }
}
