@import "styles/base";

.suggestion-card-wrapper {
    height: 100%;
    width: 100%;
    padding: 32px;
    background: white;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $color-N500;

    .deny-reason-overlay {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .deny-reason-wrapper {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 0;
        background: #fafafa;
    }

    .deny-reason-title {
        height: 20px;
        padding-right: 10px;
        white-space: nowrap;
    }

    .deny-reason-box {
        display: inline-flex;
        flex-direction: column;
        width: fit-content;
        padding: 10px 30px;
        border-radius: 10px;
        box-shadow: 0 2px 3px 0 #888;
    }

    .deny-reason-row {
        padding-top: 5px;
        display: inline-flex;
    }

    .deny-reason-text {
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
    }

    & > .suggestion-card__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        & > .suggestion-card__header-content {
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            & > .samplead-body {
                color: $color-N300;
            }

            & > .samplead-body-bold {
                color: $color-N500;
                font-size: large;
                font-weight: bold;
            }

            & .suggestion-card-name {
                width: 350px;
                height: 30px;
                display: flex;
                align-items: center;
                gap: $gap-10;

                & > img {
                    width: 30px;
                    height: 30px;
                    background: $color-turquoise300;
                    border: 1px solid $color-N100;
                    border-radius: 50%;
                }

                & > .suggestion-card-name--text {
                    width: 300px;

                    & .samplead-title-2 {
                        line-height: 40px;
                    }
                }
            }
        }

        & > .suggestion-card__header-icons {
            width: 32px;
            height: 32px;

            & .suggestion-card--hot-indicator-icon {
                position: absolute;
                top: -11px;
                left: 3px;
                width: 35px;
                height: 57px;
                background: url("assets/images/spritesheet.png") no-repeat -20px -320px;
                transform: scale(0.6);
            }
        }
    }

    & > .suggestion-badges {
        display: flex;
    }

    & > .suggestion-card__title {
        width: 282px;
    }

    & > .suggestion-card-main {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    & > .suggestion-card-main--footer {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    & > .suggestion-card__footer {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;

        & > div:last-of-type {
            & .button {
                background: $background-success-100;
            }
        }
    }
}
