.toggle-button {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.toggle-on {
    background-color: #a53aff;
}

.toggle-knob {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    transition: left 0.2s ease;
}

.toggle-on .toggle-knob {
    left: 32px;
}
