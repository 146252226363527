@import "styles/base";

.suggestions-list-wrapper {
    //outline: 1px solid red; //TODO: outline-test
    position: absolute;
    top: -50vh;
    right: -45vw;
    width: 60vw;
    min-height: 93vh;
    height: fit-content;

    padding: 32px 0px;
    background: $color-N100;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    //color: $color-N500;

    //& > * {
    //  outline: 1px solid green; //TODO: outline-test
    //}

    & > .suggestions-list-header {
        padding: 0px 32px;
        margin-bottom: 32px;
        color: black;

        & > .suggestions-list-header-close {
            display: flex;
            justify-content: flex-end;
            height: fit-content;

            & > .close {
                cursor: pointer;
            }
        }

        & > .suggestions-list-header-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 50px;

            display: flex;
            align-items: center;
            column-gap: 10px;

            span:nth-child(2) {
                color: rgba(122, 122, 122, 0.5);
            }
        }
    }

    & > .suggestions-list__content {
        //outline: 1px solid blue; //TODO: outline-test
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 6px;

        & > div.no-suggestions {
            width: 362px;
            height: 132px;
            margin-top: 200px;
            display: flex;
            flex-direction: column;
            //justify-content: center;
            align-items: center;

            & > p.samplead-menu-header {
                color: black;
            }

            & > p.samplead-body {
                color: $color-N400;
            }
        }
    }
}
