.oauth2-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
}

.oauth2-header {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #333;
}

.oauth2-btn {
    display: inline-block;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.oauth2-btn-accept {
    background-color: #4caf50;
    color: #fff;
}

.oauth2-btn-accept:hover {
    background-color: #43a047;
}

.oauth2-btn-deny {
    background-color: #f44336;
    color: #fff;
}

.oauth2-btn-deny:hover {
    background-color: #e53935;
}

.oauth2-page-container {
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.oauth2-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
}

.oauth2-error-message {
    font-size: 1.2rem;
    margin: 20px;
    padding: 15px;
    border-radius: 5px;
    color: #f44336;
    background-color: #ffebee;
}

.oauth2-client-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
}

.oauth2-loading {
    font-size: 1.2rem;
    color: #666;
}
