.signup-cont > * > p {
    padding: 3px;
}
.signup-cont > label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    margin: 5px 0;
    /* margin-right: auto; */

    /* Naturals / N500 */

    color: #353535;
}
.signup-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 600px;
    height: 600px;
    background-color: #fff;
    margin: 100px;
    border-radius: 10px;

    padding: 0 100px;
}

.linkedin_btn {
    box-sizing: border-box;
    cursor: pointer;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;

    width: 424px;
    height: 36px;

    border: 1px solid #a54cff;
    background-color: #fff;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.linkedin_btn > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.linkedin_btn > div > h4 {
    /* Label */

    width: 148px;
    height: 20px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: "tnum" on, "lnum" on;

    color: #a54cff;

    /* Inside auto layout */

    flex: none;
    order: 4;
    flex-grow: 0;
}

.signup_input {
    /* Input */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 10px;
    gap: 10px;

    width: 424px;
    height: 36px;

    background: #ffffff;
    /* Naturals / N200 */

    border: 1px solid #dadada;
    border-radius: 6px;

    /* Inside auto layout */
}
.create_btn {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    cursor: pointer;

    width: 424px;
    height: 37px;

    /* Primary/ P300 */

    background: #a54cff;
    /* shadow-base */

    /* box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08); */
    border-radius: 4px;
    border: 1px solid #fff;

    /* Inside auto layout */

    flex: none;
    order: 0;
}
.forgot-pass {
    /* width: 424px; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;

    margin: 15px 0;
    margin-left: auto;

    /* Naturals / N300 */

    color: #7a7a7a;
}

/* checkbox */

.checkbox-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    justify-content: space-between;
    margin-top: 10px;
}

/* Basic styling */

[type="checkbox"] {
    width: 20px;
    height: 20px;
    color: #1a90fd;
    vertical-align: middle;
    /* -webkit-appearance: none; */
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 10px;
    background-color: #ffffff;
    transition: background 300ms;
    cursor: pointer;
}

/* Pseudo element for check styling */

[type="checkbox"]::before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    color: transparent;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

[type="checkbox"]:checked {
    /* background-color: hsl(270,100%,65); */
    background-color: currentcolor;
}

[type="checkbox"]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */

[type="checkbox"]:disabled {
    background-color: #ccd3d8;
    opacity: 0.84;
    cursor: not-allowed;
}

/* IE */

[type="checkbox"]::-ms-check {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    color: transparent;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type="checkbox"]:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.is-member-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
