
.account_settings_container{
  margin: 30px;
}
.cancel_modal{
  margin: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cancel_modal_buttons{
  margin: 30px;
  flex-direction: row;
}



.header-container {
  background-color: #f2f2f2;
  padding: 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.account_settings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account_settings_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.account_settings_item_title {
  font-weight: bold;
  width: 40%;
}

.account_settings_item_value {
  width: 60%;
  text-align: right;
}
