.circle-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
    /* font-weight: bold; */
    color: #fff;
    padding: 0 10px;
}

.selected {
    stroke: red;
    stroke-width: 3px;
}
