.pricing-box {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
    font-family: "Poppins";
    background-color: #fbfbff;
    line-height: inherit;
}

.header-pricing {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.icon-pricing {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
}

.pricing-plan-text {
    font-size: 1.5rem;
    font-weight: bold;
}

.pricing-plan-note-text {
    font-size: 1rem;
    color: #777;
    margin-bottom: 1rem;
}

.pricing-plan-price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.pricing-plan-small-text {
    font-size: 1rem;
    font-weight: normal;
}

.pricing-plan-content-wrapper {
    margin-bottom: 2rem;
}

.pricing-plan-content-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.pricing-plan-tick-icon {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.pricing-plan-content-text {
    font-size: 1rem;
}

.separator-button-2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #1e5df8;
    color: #fff;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
}

.separator-button-2:hover {
    background-color: #1a52d2;
}

.pricing-box-card {
    border-radius: 15px;
    padding: 30px;
    border-width: 0;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
}
