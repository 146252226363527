@import "styles/base";

.lead-button {
    padding: 20px;
    height: 100%;
    width: 389px;

    background: $color-background;
    border: 1px solid $color-N100;
    border-radius: $radius-6;
    cursor: pointer;

    &:hover {
        border: 1px solid $color-P400;
        background: $color-N100;
    }

    & .lead-button-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;

        p.samplead-body--large {
            color: $color-N500;
        }

        p.samplead-body {
            width: 280px;
            color: $color-N400;
        }
    }
}
