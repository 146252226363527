.input-form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.input-form-group label {
    color: #353535;
}
.input-form-group .input-container {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 6px;
    color: #353535;
}
.input-form-group .input-container input {
    width: 100%;
    outline: none;
    border: none;
}
.input-form-group .input-container input::placeholder {
    color: #dadada;
}
.input-form-group .input-container input:focus {
    border-color: #dadada;
}
.input-form-group .input-container:hover {
    border-color: #535353;
}
.input-form-group .input-container:focus-within {
    border-color: #535353;
}
.input-form-group .input-container.warning {
    border-color: #ea234f;
}
.input-form-group .input-container.disabled {
    background: #f2f2ff;
    color: #dadada;
}
.input-form-group .input-message {
    width: 100%;
    text-align: left;
    white-space: nowrap;
}
.input-form-group .input-message.warning {
    color: #ea234f;
}

.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 10px;
    padding-bottom: 13px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid black;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid #535353;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

