/* card */
.integration-card {
    position: relative;
    width: 380px;
    height: 180px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 23px;
    background: #ffffff;
    border: 1px solid rgba(218, 218, 218, 0.5);
    box-shadow: 0px 2px 8px rgba(171, 171, 171, 0.37);
    border-radius: 6px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}

.integration-card-outer {
    cursor: pointer;
}

.installed-badge {
    position: absolute;
    transform: translateX(230%);

    padding: 3px 8px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    border-radius: 6px;

    color: #005a29;
    background-color: #e9f9f0;
}
.no-integrations {
    margin: auto;
    width: 300px;
    height: 160px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
}

.coming-soon > div {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    color: grey;
}

.coming-soon-badge {
    position: absolute;
    transform: translate(-27%, -12%);

    z-index: 99;
}

/* integrations */
.header-outreach {
    margin-top: 50px;
}
.header-outreach > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #535353;
}
.samplead-integrations-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin: 30px;
}
.samplead-integrations-cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    padding: 32px;
    gap: 20px;
    flex-wrap: wrap;

    width: 850px;
    max-height: 500px;
    overflow-y: overlay;

    /* White */

    background: #ffffff;
    border-radius: 6px;
    /* box-shadow: 0px 2px 8px rgba(171, 171, 171, 0.37); */

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}
.samplead-integrations-cards-container::-webkit-scrollbar {
    width: 5px; /* Specify the width of the scrollbar, make it slimmer */
}

.samplead-integrations-cards-container::-webkit-scrollbar-track {
    background-color: transparent; /* Specify the background color of the track, set transparent if you don't want it to be visible */
}

.samplead-integrations-cards-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Specify the color of the scrollbar */
    border-radius: 2px; /* Optional: Add border-radius to the scrollbar to make it look smoother */
}

.samplead-integrations-cards-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Specify the color of the scrollbar when hovered */
}
/* Firefox scrollbar */
.samplead-integrations-cards-container {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

body {
    background: #fbfbff;
}

.integration-card-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Naturals / N500 */

    color: #353535;
}
