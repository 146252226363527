.statistics-svg-background {
    display: flex;
    align-items: center;
    justify-content: center;

    /* background: linear-gradient(0deg, #F68634, #F68634), linear-gradient(0deg, #F68634, #F68634), #2ED47A; */
    background-color: hsl(23, 78%, 91%);
    border-radius: 6px;
    width: 50px;
    height: 50px;
}

.statistics-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 20px;
}
.statistics-header-container > * {
    margin-bottom: 7px;
}

.campaigns__header-top > * {
    color: #535353;
    text-decoration: none;
}
