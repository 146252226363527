.blacklist-modal-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;
    text-align: center;
}

.blacklist-modal-header h4 {
    margin: 0;
    font-size: 18px;
}

.blacklist-modal-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.blacklist-modal-body-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 200px;
    width: 100%;
}

.blacklist-modal-body-list-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.blacklist-modal-body-list-item input[type="checkbox"] {
    margin: 0;
}

.blacklist-modal-body-list-item label {
    cursor: pointer;
}

.blacklist-modal-footer {
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 15px;
}
