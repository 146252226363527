@import "styles/base";

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: $top-nav-height;
    left: $sidebar-width;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 9999;
    //overflow: hidden;

    &.modal--closed {
        display: none;
    }

    & .modal-overlay {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: inherit;
        left: inherit;
        //right: 0;
        //bottom: 0;
        width: 100%;
        height: 100%;
    }

    & .modal-content {
        position: relative;
        //width: 500px;
        background-color: #fff;
        margin: 40px auto;
        border-radius: 3px;
        z-index: 2;
        text-align: left;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    }
}
