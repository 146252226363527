.login {
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}


.login-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
