body {
    /* margin: 0; */
    /* background: #151616; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --bg: #ffffff;
    --bg-accent: #ffffff;
    --text-color: #242526;
    --nav-size: 60px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

/* <li> */
.nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon Button */
.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #f2f2ff;
    border-radius: 50%;
    padding: 6px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.icon-button:hover {
    filter: brightness(1.2);
}

.icon-button svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
}

/* Dropdown Menu */

.dropdown-menu {
    /* position: absolute; */
    /* top: 38px; */
    width: 180px;
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    /* padding: 1rem; */
    overflow: hidden;
    transition: height var(--speed) ease;
    box-shadow: 0px 20px 35px rgba(87, 87, 87, 0.15);
    border-radius: 11px;
    z-index: 99;
}

.menu {
    width: 100%;
}

.expandi-menu-title {
    height: 50px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
    /* border-radius: 11px; */
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
    filter: none;
}

.menu-item:hover {
    /* border-radius: 11px; */
    background-color: #ecdbff;
    cursor: pointer;
}

.icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}

.menu-secondary-enter {
    transform: translateX(-110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-secondary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.sequence-svg {
    margin: 0 12px;
}

.side-arrow-svg {
    margin-right: 20px;
}

.bottom-spinner {
    position: absolute;
    bottom: 0;
    left: 0;
}

.menu-outreach {
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 20px 35px rgba(87, 87, 87, 0.15);
    border-radius: 11px;
    height: 500px;
    overflow-y: scroll;
}

.all-menus {
    position: absolute;
    top: 40px;
    right: 0px;
    width: 630px;
    display: flex;
    flex-direction: row-reverse;

    z-index: 99;
}

.integration-search > input:focus {
    outline: none;
}
.integration-search > input {
    background-color: transparent;
    border: none;
    background-color: #ffffff;
    border-radius: 4px;
    background: transparent;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;

    color: #a7a7a7;
}
.integration-search {
    height: 30px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    border: 1px solid #dadada;
    border-radius: 4px;
}
.integration-search > label {
    margin-left: 5px;
}

.searchbox-integration {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.search-input {
    width: 110%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
    margin: 10px;
}
