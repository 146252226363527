.badge {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    cursor: pointer;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    font-feature-settings: "tnum" on, "lnum" on;
    border-radius: 2px;
    color: #ffffff;
}
.badge.default {
    background-color: #2f2f2f;
}
.badge.success {
    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
}
.badge.warning {
    background: #f68634;
}
.badge.danger {
    background: #ea234f;
}

