.customButton {
    margin: 5px;
}

.button-group-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: rgb(225, 224, 224);
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
}
.button-group-container > * {
    margin: 5px;
}
