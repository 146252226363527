.all-campaigns-wrapper {
    width: 100%;
    height: 100%;
    padding: 60px 0 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.all-campaigns-wrapper > div.all-campaigns {
    width: 1184px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 32px;
}
.all-campaigns-wrapper > div.all-campaigns > header.all-campaigns-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #353535;
}
.all-campaigns-wrapper > div.all-campaigns > main.all-campaigns-main {
    background: white;
    border-radius: 6px;
    width: 100%;
    min-height: 634px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    column-gap: 21px;
    row-gap: 22px;
}
.all-campaigns-wrapper > div.all-campaigns > main.all-campaigns-main.no-campaigns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.all-campaigns-wrapper > div.all-campaigns > main.all-campaigns-main.no-campaigns p.samplead-body {
    width: 462px;
    color: #4c5862;
}

/*# sourceMappingURL=AllCampaigns.css.map */
