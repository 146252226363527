@import "styles/base";
//border: 1px solid green;//TODO: border-test

.manual-discovery__container {
    width: 1080px;
    max-height: 450px;
    overflow: scroll;
    padding: $gap-32;

    background: $color-white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: $radius-6;

    & > .manual-discovery__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 10px;

        & > h3 {
            color: $color-N500;
        }

        & > .manual-discovery__form {
            width: inherit;
            height: 107px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
        }

        & > .manual-discovery__content--buffer {
            width: 100%;
            height: 10px;
        }
    }
}

.manual-discovery__content--instruction {
    //border: 1px solid red;//TODO: border-test
    width: 100%;

    & > .samplead-body {
        color: $color-N400;
        margin-bottom: 4px;
    }
}

.error-content {
    padding: 20px;
    color: red;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
}
