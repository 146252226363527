.button__container {
    position: relative;
}
.button__container div.button__menu {
    position: absolute;
    top: 59px;
    left: -65px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2ff;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 8px rgba(167, 167, 167, 0.5));
}
.button__container div.button__menu > ul {
    padding: 12px 15px;
    list-style: none;
}
.button__container div.button__menu > ul > li {
    width: 120px;
    color: #a54cff;
    cursor: pointer;
}
.button__container div.button__menu > ul > li:hover {
    color: #5f2a94;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 36px;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    font-feature-settings: "tnum" on, "lnum" on;
}
.button.button--primary {
    background: #a54cff;
    color: #ffffff;
}
.button.button--primary:hover {
    background: #7b31c6;
}
.button.button--primary:active {
    background: #5f2a94;
}
.button.button--primary:disabled {
    background: #dadada;
}
.button.button--secondary {
    background: transparent;
    color: #a54cff;
    border: solid 1px #a54cff;
}
.button.button--secondary:hover {
    background: #f2f2ff;
}
.button.button--secondary:active {
    background: #f2f2ff;
    color: #5f2a94;
    border-color: #5f2a94;
}
.button.button--secondary:disabled {
    color: #ffffff;
    background: #dadada;
    border: 1px solid #ffffff;
}
.button.button--primary-white {
    color: #a54cff;
    background: #ffffff;
}
.button.button--primary-white:hover {
    background: linear-gradient(0deg, rgba(159, 75, 255, 0.2), rgba(159, 75, 255, 0.2)), #ffffff;
}
.button.button--primary-white:active {
    background: linear-gradient(0deg, rgba(159, 75, 255, 0.4), rgba(159, 75, 255, 0.4)), #ffffff;
}
.button.button--primary-white:disabled {
    background: #ffffff;
    color: #dadada;
}
.button.button--secondary-white {
    color: #ffffff;
    border: solid 1px #ffffff;
}
.button.button--secondary-white:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #a54cff;
}
.button.button--secondary-white:active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #9f4bff;
}
.button.button--secondary-white:disabled {
    background: #dadada;
}
.button.button--link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    color: #a54cff;
    font-weight: 500;
    column-gap: 13.92px;
    padding: 0;
}
.button.button--link:disabled {
    color: #a7a7a7;
}
.button.button--danger {
    background: linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
    color: #ffffff;
}
.button.button--danger:hover {
    background: linear-gradient(0deg, #b22645, #b22645), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
}
.button.button--danger:active {
    background: linear-gradient(0deg, #78001b, #78001b), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
}
.button.button--danger:disabled {
    background: #dadada;
}
.button.button--kebab-menu {
    background: transparent;
    width: 44px;
    color: #353535;
    display: flex;
    justify-content: center;
    padding: 9.45px 18.65px;
    text-align: center;
    align-self: center;
}
.button.button--kebab-menu .button__content {
    background: url("assets/images/spritesheet.png") no-repeat -20px -460px;
    width: 5px;
    height: 18px;
}
.button.button--kebab-menu:active {
    background: #f2f2ff;
}
.button > .button__content {
    display: flex;
    align-items: center;
    width: max-content;
}
.button.borderless {
    border-color: transparent;
}

.button__notification {
    position: absolute;
    top: -10px;
    right: -7px;
    min-width: 24px;
    min-height: 24px;
    padding: 3px;
    font-size: 14px;
    border-radius: 50%;
    background: linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
    color: #ffffff;
    z-index: 1;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

