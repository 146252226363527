.progress-bar-label {
    /* align-items: center; */
    text-align: center;
    width: 120%;
}
.progress-bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 45px;
    row-gap: 20px;
    text-transform: uppercase;
}

.progress-bar-wrapper > .progress-bar-container {
    height: 10px;
    width: 400px;
    background-color: #d99dff;
    border-radius: 50px;
}

.progress-bar-wrapper > .progress-bar-container > .progress-bar-filler {
    background-color: #f7f7f7;
    max-width: 100%;
    height: 400px;
    border-radius: inherit;
    text-align: right;
    transition: width 0.3s ease-in-out;
}

.progress-bar-wrapper > .progress-bar-container > .progress-bar-filler > .progress-bar-label {
    padding: 5px;
    color: white;
    font-weight: bold;
}

.progress-bar-label-wrapper p {
    color: white;
    display: flex;
    width: 400px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
}

