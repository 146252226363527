.lead-row-container {
    width: 100%;
}
.lead-row-container p {
    color: #353535;
}
.lead-row-container > .lead-row-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /** top right bottom left */
    padding: 6px 20px 6px 26px;
}
.lead-row-container > .lead-row-top > .unread > * {
    font-weight: bold;
    color: black;
}
.lead-row-selected {
    background: #ecdbff;
}
.lead-row-container > .lead-row-top > .lead-row-name {
    width: 222px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.lead-row-container > .lead-row-top > .lead-row-name > img {
    width: 30px;
    height: 30px;
    /* background: #07C3D7; */
    /* border: 1px solid #F2F2FF; */
    border-radius: 50%;
}
.lead-row-container > .lead-row-top > .lead-row-name > * > img {
    width: 30px;
    height: 30px;
    /* background: #07C3D7; */
    /* border: 1px solid #F2F2FF; */
    border-radius: 50%;
}
.lead-row-container > .lead-row-top > .lead-row-name > .lead-row-name--text {
    width: 166px;
}
.lead-row-container > .lead-row-top > .lead-row-name > .lead-row-name--text > .lead-row-name--text-inner {
    width: 100%;
}
.lead-row-container > .lead-row-top > .lead-row-title,
.lead-row-container > .lead-row-top > .lead-row-mail {
    width: 251.89px;
}
.lead-row-container > .lead-row-top > .lead-row-title .lead-row-title--text,
.lead-row-container > .lead-row-top > .lead-row-mail .lead-row-title--text {
    /* controller row cell size */
    width: 400px;
}
.lead-row-container > .lead-row-top > .lead-row-title .lead-row-mail--text,
.lead-row-container > .lead-row-top > .lead-row-mail .lead-row-mail--text {
    width: 189px;
}
.lead-row-container > .lead-row-top > .lead-row--hot-indicator {
    position: relative;
    width: 73px;
    height: 26px;
}
.lead-row-container > .lead-row-top > .lead-row--hot-indicator .lead-row--hot-indicator-icon {
    position: absolute;
    top: -16px;
    left: -8px;
    width: 35px;
    height: 57px;
    background: url("assets/images/spritesheet.png") no-repeat -20px -320px;
    transform: scale(0.45);
}
.lead-row-container > .lead-row-top > .lead-row-buttons {
    display: flex;
    width: 250px;
    height: 36px;
    justify-content: flex-end;
}
.lead-row-container > .lead-row-top > .lead-row-buttons .lead-row-buttons-remove .button {
    border-color: transparent;
}
.lead-row-container > .lead-row-top > .lead-row-buttons .lead-row-buttons-message .button {
    padding: 8px 11px;
    border-color: transparent;
}
.lead-row-container > .lead-row-top > .lead-row-buttons .lead-row-buttons-message .lead-row-buttons-message--icon-expand {
    width: 20px;
    height: 20px;
    background: url("assets/images/spritesheet.png") no-repeat -200px -180px;
}
.lead-row-container > .lead-row-top > .lead-row-buttons .lead-row-buttons-message .lead-row-buttons-message--icon-collapse {
    width: 20px;
    height: 20px;
    background: url("assets/images/spritesheet.png") no-repeat -240px -180px;
}
.lead-row-container > .lead-row-bottom-wrapper {
    /* padding: 20px; */
    /* height: 440px; */
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 32px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom .samplead-body,
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom .samplead-body--bold {
    color: #353535;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__left,
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__right {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom .lead-row-bottom--content {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 20px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__left {
    width: 610px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__left > footer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: 20px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__right {
    width: 502px;
    /* height: 312px; */
    justify-content: space-between;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__right .samplead-body.lrbc--message {
    max-height: 140px;
    padding: 10px;
    border: 1px solid #7a7a7a;
    border-radius: 6px;
    overflow-y: auto;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__right > .lead-row-bottom__right--tags {
    height: 107px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
.lead-row-container
    > .lead-row-bottom-wrapper
    > .lead-row-bottom
    > .lead-row-bottom__right
    > .lead-row-bottom__right--tags
    > .lead-row-bottom__right--badges {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 6px;
}
.lead-row-container > .lead-row-bottom-wrapper > .lead-row-bottom > .lead-row-bottom__right > footer {
    display: flex;
    justify-content: flex-end;
}
.lead-row-container > .lead-row-message--divider {
    width: 100%;
    height: 1px;
    background: #f2f2ff;
    margin: 6px 0;
}
.lead-row-title--exported {
    margin-left: 50px;
}
.lead-row-title--exported {
    margin-left: 50px;
}
.move-right {
    margin-left: 90px;
}
.highlight_selected {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: solid 2px rgb(193, 131, 255);

    border-radius: 10px;
    background-color: #fbfbff;
    border-image: linear-gradient(90deg, rgba(178, 85, 226, 1) 41%, rgba(140, 16, 207, 1) 100%);
}
.hover-title {
    position: absolute;

    padding: 5px 4px;
    color: #f2f2ff;
    font-size: 12px;
    background-color: #353535;
    border-radius: 5px;
    width: auto;
    min-width: 100px;
    max-height: 30px;
}

.indicate-error-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.indicate-error-icon {
}
.hotness-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 25px;
    height: 36px;
}
.message-on-hover {
    position: absolute;
    bottom: 0;
    width: 313px;
    margin-bottom: 40px;
    transform: translateX(-54%);
    font-size: 0.9rem;

    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 15px 60px rgba(87, 87, 87, 0.19);
    border-radius: 11px;

    z-index: 9991;
}
.chat-bubble {
    position: absolute;
    top: -13px;
    right: 4.5px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    margin-left: 49%;

    /* background: #D9D9D9; */
    background: #ffffff;
    transform: rotate(-45deg);
    z-index: 99;
}

.email-cell {
    display: flex;
    justify-content: center;
    width: 50px;
}
.phone-cell {
    display: flex;
    justify-content: center;
    width: 50px;
}
.move-exported-right {
    margin-left: 75px;
}
.center-abs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.origin-chip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    max-width: 70px;
}

.rephrase-emoji {
    width: 1.7em;
    margin: 5px auto 5px auto;
}
