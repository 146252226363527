.tooltip {
    position: relative;
}

.tooltip-bubble {
    width: max-content;
    position: absolute;
    z-index: 10;
    display: flex;
}
.tooltip-bubble > .hot-lead {
    width: 14px;
    height: 14px;
    background: url("assets/images/spritesheet.png") no-repeat -20px -426px;
}
.tooltip-bubble::after {
    content: "";
    position: absolute;
}
.tooltip-bubble.tooltip-top {
    bottom: 100%;
    left: -20%;
    padding-bottom: 3px;
}
.tooltip-bubble.tooltip-top-left {
    bottom: 100%;
    right: 0;
    padding-bottom: 3px;
    transform: translateX(-50%);
}
.tooltip-bubble.tooltip-top-right {
    bottom: 100%;
    left: 0;
    padding-bottom: 3px;
    transform: translateX(50%);
}
.tooltip-bubble.tooltip-bottom {
    top: 100%;
    left: 50%;
    padding-top: 9px;
    transform: translateX(-50%);
}
.tooltip-bubble.tooltip-left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    transform: translateY(-50%);
}
.tooltip-bubble.tooltip-right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    transform: translateY(-50%);
}

.tooltip-message {
    background: rgba(47, 47, 47, 0.9);
    border-radius: 4px;
    padding: 1px 6px;
    background: rgba(47, 47, 47, 0.9);
    color: #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
}

