@use "styles/base";
//border: 1px solid green;//TODO: border-test

.export-confirmation {
    width: 555px;
    height: 216px;
    padding: 32px;
    background-image: url("assets/svgs/exportBackground.svg");
    background-repeat: no-repeat;
    color: base.$color-N500;

    & > .export-confirmation__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 26px;

        & a {
            text-decoration: none;
            color: inherit;
        }
    }
}
