.suggestion-card-wrapper {
    height: 100%;
    width: 100%;
    padding: 32px;
    background: white;
    display: flex;
    flex-direction: column;
    color: #353535;
    padding-left: 70px;
}
.suggestion-card-wrapper .deny-reason-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.suggestion-card-wrapper .deny-reason-wrapper {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 0;
    background: #fafafa;
}
.suggestion-card-wrapper .deny-reason-title {
    height: 20px;
    padding-right: 10px;
    white-space: nowrap;
}
.suggestion-card-wrapper .deny-reason-box {
    display: inline-flex;
    flex-direction: column;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 #888;
}

.suggestion-card-wrapper .deny-reason-row {
    padding-top: 5px;
    display: inline-flex;
}

.suggestion-card-wrapper .deny-reason-text-box {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid hsl(270, 100%, 65%, 30%);
    /* background-color: red; */
    white-space: nowrap;
    user-select: none;
    /* cursor: pointer; */
}

.suggestion-card-wrapper .deny-reason-buttons-box {
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.deny-reason-grid-item {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.suggestion-card-wrapper .deny-reason-text {
    margin: 1px;
    padding: 2px;
    /* border-radius: 5px; */
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
}
.suggestion-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.suggestion-card__header > .suggestion-card__header-content {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}
.suggestion-card__header > .suggestion-card__header-content > .samplead-body {
    color: #7a7a7a;
}
.suggestion-card__header > .suggestion-card__header-content > .samplead-body-bold {
    color: #7a7a7a;
    font-size: large;
    font-weight: bold;
}
.suggestion-card__header > .suggestion-card__header-content .suggestion-card-name {
    width: 350px;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.suggestion-card__header > .suggestion-card__header-content .suggestion-card-name > * > img {
    width: 30px;
    height: 30px;
    border: 1px solid #f2f2ff;
    border-radius: 50%;
}
.suggestion-card-wrapper
    > .suggestion-card__header
    > .suggestion-card__header-content
    .suggestion-card-name
    > .suggestion-card-name--text
    .samplead-title-2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
}
.suggestion-card__header > .suggestion-card__header-icons {
    width: 32px;
    height: 32px;
}
.suggestion-card__header > .suggestion-card__header-icons .suggestion-card--hot-indicator-icon {
    position: absolute;
    top: -11px;
    left: 3px;
    width: 35px;
    height: 57px;
    background: url("assets/images/spritesheet.png") no-repeat -20px -320px;
    transform: scale(0.6);
}
.suggestion-badges {
    display: flex;
}
.suggestion-card__title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
}
.suggestion-card-main {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.suggestion-card-main--footer {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.suggestion-card__footer > div {
    display: flex;
    justify-content: space-between;
}

/* .suggestion-card__footer > div > div:last-of-type .button {
    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
} */

#root
    > div
    > div
    > div.modal-container.suggestion-list-modal
    > div.modal-content
    > div
    > div.suggestions-list__content
    > div
    > footer
    > div:nth-child(2)
    > div:nth-child(2)
    > div:nth-child(2)
    > button {
    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
}

.skip_btn {
    margin: 5px;
}

.magic_wand_suggested {
    margin-right: 20px;
    width: 40px;
    height: 20px;
}

.fire-icon {
    padding: 0 15px;
}

/* Basic styling */

[type="checkbox"] {
    width: 20px;
    height: 20px;
    color: #1a90fd;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #ffffff;
    transition: background 300ms;
    cursor: pointer;
}

/* Pseudo element for check styling */

[type="checkbox"]::before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    color: transparent;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

[type="checkbox"]:checked {
    /* background-color: hsl(270,100%,65); */
    background-color: currentcolor;
}

[type="checkbox"]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */

[type="checkbox"]:disabled {
    background-color: #ccd3d8;
    opacity: 0.84;
    cursor: not-allowed;
}

/* IE */

[type="checkbox"]::-ms-check {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    color: transparent;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type="checkbox"]:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/*  */
.suggestion-message {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #353535;
}
.suggestion-card__employees {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 220% */

    color: #7a7a7a;
}
.suggestion-card__employees > a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 220% */

    color: #7a7a7a;
}

.suggestion-card__employees > a:hover {
    color: #d3d3d3;
    transition: 0.6s;
}

.tooltip-style {
    font-size: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
    max-width: 250px;
    border-radius: 15px;
}
