.export-confirmation {
    width: 555px;
    height: 216px;
    padding: 32px;
    background-image: url("assets/svgs/exportBackground.svg");
    background-repeat: no-repeat;
    color: #353535;
}
.export-confirmation > .export-confirmation__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 22px;
}
.export-before > .export-confirmation__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 26px;
}
.export-confirmation > .export-confirmation__content a {
    text-decoration: none;
    color: inherit;
}

