.exported-options-popup {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 20px 35px rgba(87, 87, 87, 0.15);
    border-radius: 11px;
    right: 0;
}
.options-list {
    list-style: none;
}
.options-item {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 10px 20px;
    cursor: pointer;
}
.option-icon {
    margin-right: 10px;
}

.export-before {
    width: 555px;
    height: 216px;
    padding: 32px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    color: #353535;
}

.export-button {
    position: relative;
}

.text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
