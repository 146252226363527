.integrations-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: "Poppins";
    font-style: normal;
    font-size: 20px;
    line-height: 50px;
}
.integrations-header > * {
    margin: 5px;
}

.integrations-container {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 50px 0;
}

.integrations-main-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 0px;
    gap: 16px;

    width: 876px;
    height: 557px;

    /* White */

    background: #ffffff;
    border-radius: 6px;
}
.integrations-main-content > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.integrations-main-content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

/* side actions */

.integrations-side-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    margin: 0 12px;
}

.install-button {
    width: 264px;
    height: 40px;

    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
    border-radius: 6px;
    color: #ffffff;
    border: none;

    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.integrations-side-actions > ul {
    list-style: none;
}
.integrations-side-actions > ul > li {
    width: 264px;

    border-bottom: 1px solid#DADADA;
    margin: 10px 5px;
    padding: 7px 0;
}
.integrations-side-actions > ul > li > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.integrations-side-actions > ul > li > p > label {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 152px;
    height: 30px;

    background: #ecdbff;
    border-radius: 6px;
    color: #000000;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.integrations-side-actions > ul > li > div {
    /* color: #A93BFF; */
    background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%), #dadada;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

/* confirm popup */

.confirm-popup-outside {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.pop-up {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: flex-start; */
    justify-content: center;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    width: 400px;
    height: fit-content;
    padding-right: 20px;

    background: #ffffff;
    border-radius: 6px;
    z-index: 1001;
}

.joined-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    font-weight: bolder;
}
.joined-logos > * {
    margin: 5px 0;
}
.samplead-image {
    padding-top: 10px;
}

.info-container {
    text-align: start;
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 10px;
}
.access-point-list {
    list-style: none;
    text-align: start;
    margin: 5px;
}
.access-point {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 25px;
}
.access-point > p {
    margin-left: 9px;
}
.buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px;
}
.cancel-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;

    padding: 8px 0px;

    width: 86px;
    height: 36px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.install-btn {
    cursor: pointer;
    padding: 8px 0px;

    width: 136px;
    height: 36px;

    border: none;

    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
    border-radius: 4px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    font-feature-settings: "tnum" on, "lnum" on;

    color: #ffffff;
}

.installed {
    cursor: pointer;
    padding: 8px 0px;

    width: 264px;
    height: 36px;

    background: linear-gradient(0deg, #e9f9f0, #e9f9f0), #e9f9f0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    font-feature-settings: "tnum" on, "lnum" on;

    color: #005a29;
    border-radius: 6px;
    border: transparent;
}

.retry-integration {
    cursor: pointer;
    padding: 8px 0px;

    width: 136px;
    height: 36px;

    border: none;

    background: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
    border-radius: 4px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    font-feature-settings: "tnum" on, "lnum" on;

    color: #ffffff;
}

.info-box {
    width: 264px;
    height: 136px;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding: 10px;
    margin-top: 20px;
}
.info-box > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #353535;
}
.uninstall-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px;
}

.uninstall-container > p {
    font-size: 14px;
}

.uninstall-button {
    width: 154px;
    height: 36px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    background: linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
    border-radius: 4px;
    color: #ffffff;
    border: transparent;
    cursor: pointer;
}

.error {
    color: red;
}
.external-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #000000;
    margin: 25px 0;
    border-radius: 6px;
}

.external-app-settings-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 264px;
    height: 34px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #353535;
}

.failed-install-button {
    cursor: pointer;
    padding: 8px 0px;

    width: 264px;
    height: 36px;

    background: red;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    font-feature-settings: "tnum" on, "lnum" on;

    color: #fff;
    border-radius: 6px;
    border: transparent;
}

.link-to-outreach {
    color: #0011ff;
}

.select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px;
}
.no-mailbox__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px;
}
.integrations-mailbox-select {
    width: 264px;
    height: 36px;
    border: 1px solid #dadada;
    border-radius: 6px;
}
