.input-caption-container {
    grid-gap: 0px;
    gap: 0px;
}

.no-autocomplete {
    color: white;
    padding: 8px;
}

.autocomplete_input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 10px;
    grid-gap: 10px;
    gap: 10px;
    height: 36px;
    left: 399.5px;
    top: 170px;
    background: #ffffff;
    color: #000000;

    border-radius: 6px;
}

.autocomplete {
    border: 1px solid black;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    color: black;
    border-radius: 5px;
}
.autocomplete li {
    padding: 8px;
    background-color: white;
}
.autocomplete > .active,
.autocomplete li:hover {
    background-color: #a93bff;
    color: white;
    cursor: pointer;
    font-weight: 700;
}
.autocomplete li:not(:last-of-type) {
    border-bottom: 1px solid black;
}

.wide {
    width: 481px;
}
