.manual-discovery__container {
    width: 1080px;
    max-height: 800px;
    overflow: scroll;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.manual-discovery__container > .manual-discovery__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 10px;
}
.manual-discovery__container > .manual-discovery__content > h3 {
    color: #353535;
}
.manual-discovery__container > .manual-discovery__content > .manual-discovery__form {
    width: inherit;
    height: 107px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.manual-discovery__container > .manual-discovery__content > .manual-discovery__content--buffer {
    width: 100%;
    height: 10px;
}

.manual-discovery__content--instruction {
    width: 100%;
}
.manual-discovery__content--instruction > .samplead-body {
    color: #535353;
    margin-bottom: 4px;
}

.error-content {
    padding: 20px;
    color: red;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
}

.csv_buttons {
    display: flex;
    flex-direction: row;
}

.csv_buttons > * {
    margin: 5px;
}

