$color-background: #fbfbff;
$color-P100: #ecdbff;
$color-P200: #d9b7ff;
$color-P300: #a54cff;
$color-P400: #7b31c6;
$color-P500: #5f2a94;
$color-N100: #f2f2ff;
$color-N200: #dadada;
$color-N250: #a7a7a7;
$color-N300: #7a7a7a;
$color-N400: #535353;
$color-N500: #353535;
$color-black: #000000;
$color-white: #ffffff;
$color-grey150: #cecedc;
$color-dark-gray: #4c5862;
$color-cool-gray-700: #374151;
$color-turquoise300: #07c3d7;

$color-P200op40: rgba(159, 75, 255, 0.4);
$color-P200op20: rgba(159, 75, 255, 0.2);
$color-white40op: rgba(255, 255, 255, 0.4);
$color-white20op: rgba(255, 255, 255, 0.2);

$color-warning: #f68634;
$color-danger100: #ea234f;
$color-success100: #ea234f;

$background-primary-gradient-light: linear-gradient(180deg, #a93bff60 0%, #8834ff60 100%), #dadada;
$background-primary-gradient: linear-gradient(180deg, #a93bff 0%, #8834ff 100%), #dadada;
$background-success-100: linear-gradient(0deg, #22c56d, #22c56d), #2ed47a;
$background-success-200: linear-gradient(0deg, #007d39, #007d39), #2ed47a;
$background-success-300: linear-gradient(0deg, #005a29, #005a29), #2ed47a;
$background-warning-100: linear-gradient(0deg, #f68634, #f68634), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
$background-warning-200: linear-gradient(0deg, #de5e00, #de5e00), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
$background-warning-300: linear-gradient(0deg, #a34500, #a34500), linear-gradient(0deg, #f68634, #f68634), #2ed47a;
$background-danger-100: linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634),
    #2ed47a;
$background-danger-200: linear-gradient(0deg, #b22645, #b22645), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634),
    #2ed47a;
$background-danger-300: linear-gradient(0deg, #78001b, #78001b), linear-gradient(0deg, #ea234f, #ea234f), linear-gradient(0deg, #f68634, #f68634),
    #2ed47a;

$top-nav-height: 70px;
$top-nav-item-gap: 20px;
$nav-logo-width: 140px;
$sidebar-width: 100px;
$campaigns__main--width: 1184px;
$campaigns__main--inner-width: 550.51px;
$gap-10: 10px;
$gap-20: 20px;
$gap-32: 32px;

$button-height: 36px;
$radius-4: 4px;
$radius-6: 6px;

$test-border: 1px solid black;
