.messenger {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    max-width: 500px;
    min-width: 500px;
    margin: auto;
    height: 80%;
    overflow-y: auto;
    /* Custom Scrollbar */
    scrollbar-width: thin; /* "thin" or "auto" or "none" */
    scrollbar-color: #888 #f0f0f0; /* scroll thumb and track color */
}
.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}

.user-message {
    background-color: #0084ff;
    color: white;
    text-align: right;
    margin-right: 20%; /* This shifts user messages to the left */
}

.contact-message {
    background-color: #f0f0f0;
    color: black;
    text-align: left;
    margin-left: 20%; /* This shifts contact messages to the right */
}

.message-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
}

.message-body {
    margin-top: 5px;
}

.message-status {
    margin-top: 5px;
    font-size: 0.8em;
}
/* For Webkit (Chrome & Safari) */
.messenger::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

.messenger::-webkit-scrollbar-track {
    background: #f0f0f0; /* color of the tracking area */
}

.messenger::-webkit-scrollbar-thumb {
    background-color: #888; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #f0f0f0; /* creates padding around scroll thumb */
}
