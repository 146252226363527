.campaigns__container {
    position: relative;
    padding: 80px 78px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}
.campaigns__container.no-tabs {
    row-gap: 32px;
}

.campaigns__snackbar {
    position: absolute;
    top: 96px;
}

.campaigns__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 81px;
    width: 100%;
}
.campaigns__header .campaigns__header-top {
    align-self: flex-start;
    display: flex;
    color: #000000;
}
.campaigns__header .campaigns__header-top a {
    text-decoration: none;
}
.campaigns__header .campaigns__header-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    height: 50px;
    width: 100%;
}
.campaigns__header .campaigns__header-bottom > .campaigns__header-bottom-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.campaigns__header .campaigns__header-bottom > .campaigns__header-bottom-left .campaigns__header-title {
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.campaigns__header .campaigns__header-bottom > .campaigns__header-bottom-left > .campaigns__header-leads-count {
    color: rgba(122, 122, 122, 0.5);
    margin-left: 4px;
}
.campaigns__header .campaigns__header-bottom .campaigns__header-bottom-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}
.campaigns__header .campaigns__header-bottom .campaigns__header-bottom-right .button {
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
}
.campaigns__header .campaigns__header-bottom .campaigns__header-bottom-right .button--link {
    margin-right: 12.58px;
}

.campaigns__main-top {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 20px 0px;
}
.campaigns__main-top > * {
    display: flex;
    align-items: center;
}
.campaigns__main-top > .campaigns__main-top-options {
    height: 36px;
    justify-content: space-between;
}
.campaigns__main-top > .campaigns__main-top-options > .samplead-body {
    color: #353535;
}
.campaigns__main-top > .campaigns__main-top-options > .campaigns__main-top-options--buttons {
    display: flex;
    column-gap: 10px;
}
.campaigns__main-top > .campaigns__main-top-headers {
    height: 26px;
}
.campaigns__main-top > .campaigns__main-top-headers > .samplead-body {
    color: #7a7a7a;
}
.campaigns__main-top > .campaigns__main-top-headers > .samplead-body:nth-child(1) {
    margin-left: 15px;
}
.campaigns__main-top > .campaigns__main-top-headers-exported > .samplead-body:nth-child(1) {
    margin-left: 33px;
}
.campaigns__main-top > .campaigns__main-top-headers > .samplead-body:nth-child(2) {
    margin-left: 193px;
}
.campaigns__main-top > .campaigns__main-top-headers > .samplead-body:nth-child(3) {
    margin-left: 240px;
}
.campaigns__main-top > .campaigns__main-top-headers > .samplead-body:nth-child(4) {
    margin-left: 240px;
}
.campaigns__main-top > .campaigns__main-top-headers.manual > .samplead-body:nth-child(2) {
    margin-left: 223px;
}
.campaigns__main-top > .campaigns__main-top-headers.manual > .samplead-body:nth-child(3) {
    margin-left: 320px;
}

.campaigns__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1184px;
    height: 582px;
    background-color: #ffffff;
    border-radius: 6px;
}

.campaigns__main_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1184px;
    height: 422px;
    background-color: #ffffff;
    border-radius: 6px;
}

.campaigns__main.no-tabs {
    height: 582px;
    padding: 143.5px 351.5px 143.5px 374.5px;
}
.campaigns__main.empty-tab {
    height: 444px;
}
.campaigns__main.creating-lead-auto {
    height: 444px;
    padding: 142.5px;
    margin-top: 56px;
}
.campaigns__main.show-lead-list {
    height: 454px;
}
.campaigns__main .campaigns__main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 389px;
    height: 390px;
}
.campaigns__main .campaigns__main-content.creating-lead-auto {
    width: 458px;
    justify-content: flex-start;
    row-gap: 18px;
}
.campaigns__main .campaigns__main-content.creating-lead-auto .samplead-body {
    color: #535353;
}
.campaigns__main .campaigns__main-content.creating-lead-auto .leads-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    color: #535353;
}
.campaigns__main .campaigns__main-content.no-tabs {
    width: 857px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.campaigns__main .campaigns__main-content.no-tabs p.samplead-body {
    color: #4c5862;
}
.campaigns__main .campaigns__main-content.no-tabs > .campaigns__main-content--no-tabs-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}
.campaigns__main .campaigns__main-content.no-tabs > .campaigns__main-content--no-tabs-options > .campaigns__main-content--no-tabs-options-center {
    width: 39px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}
.campaigns__main
    .campaigns__main-content.no-tabs
    > .campaigns__main-content--no-tabs-options
    > .campaigns__main-content--no-tabs-options-center
    > .campaigns__main-content--no-tabs-options-divider {
    width: 1px;
    height: 76px;
    background: #a54cff;
}
.campaigns__main .campaigns__main-content.empty-tab {
    width: 458px;
    height: fit-content;
    gap: 18px;
}
.campaigns__main .campaigns__main-content.empty-tab > p.samplead-body {
    color: #535353;
}
.campaigns__main .campaigns__main-content.empty-tab > .leads-caption {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #7a7a7a;
}
.campaigns__main .campaigns__main-content.show-lead-list {
    width: 100%;
    height: 100%;
    row-gap: 10px;
}
.campaigns__main .campaigns__main-content.progress-bar {
    width: 588px;
    height: 54px;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table-column-title {
    position: relative;
    z-index: 0;
    flex: 1;
}
