@use "styles/base";

div.editable-text {
    padding: 5px;
    width: 500px; //TODO: make this dynamic
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: base.$radius-6;
    color: base.$color-N500;

    & > input {
        width: 100%;
        outline: none;
        border: none;
    }
}
