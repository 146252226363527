.navigation-container {
    /* left */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    position: absolute;
    bottom: 113px;
    right: 200px;
    left: 200px;
    height: 71px;
    z-index: 1;
    /* border: 1px solid #000000; */
}

.navigation-container-button {
    /* Label */
    width: 30px;
    height: 20px;
    z-index: 10001;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    font-feature-settings: "tnum" on, "lnum" on;
    color: #a54cff;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.purple-button {
    color: #a54cff;
}

@media (max-width: 900px) {
    .navigation-container {
        /* left */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 100%;
        height: 71px;
        z-index: 10001;
        position: initial !important;
        /* border: 1px solid #000000; */
        bottom: 60px;
        right: initial;
    }
}
