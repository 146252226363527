.source-lead-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    /* border-bottom: 1px solid #e6e6e6; */
    height: 50px;
    background: #fbfbff;
    border-radius: 4px;
    margin-bottom: 5px;
}

.source-lead-row-found {
    background: #ecdbff;
}

.source-lead-row-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Naturals / N500 */

    color: #353535;
}

.source-lead-row-titles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    height: 50px;
    font-weight: bold;
    padding-left: 100px;
}

.title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    /* Primary/ P500 */

    color: #5f2a94;
}

.source-lead-row-image {
    /* border-top: solid 1px #6d6d6d; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    margin-right: 20px;
}

.source-lead-row-delete {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: "tnum" on, "lnum" on;

    /* Primary/ P500 */

    color: #bb405c;
}
