@use "styles/base";

table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
    color: #4c5862;

    th {
        padding: 10px 0;
        font-size: 0.8rem;
    }
    tr {
        td {
            // border-top: 1px solid rgb(160, 160, 160);
            // padding: 15px 0px;
            // font-size: 0.8rem;

            &:nth-child(2) {
                // width: 50%;
            }
            &:nth-child(3) {
                // width: 25%;
            }
        }
    }
}
