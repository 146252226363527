.source-list-background {
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.2);
}

/* Modal Content/Box */
.source-list-container {
    background-color: #fefefe;
    margin: 30% auto; /* 15% from the top and centered */
    padding: 20px;
    border-radius: 15px;
    border: transparent;

    width: 98%;
    /* height: 50%; */
}

.page-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: 10px;
}

.source-list-header {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.found-magic-moments-container {
    width: 400px;
}
.source-list-header-label {
    font-size: 10px;
    font-weight: bold;
    margin-left: 50px;
}
.source-list-header > div > div {
    margin-top: 0px;
    margin-left: 50px;
    width: 200px;
}

.close-source-list-button-container {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-bottom: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #f4f4f4;
    padding: 15px;
}
.close-source-list-button {
    cursor: pointer;
    border: none;
    background: transparent;
}

.source-list-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.empty-source-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    gap: 10px;
}

.filters-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.filters-container > input {
    border: 2px solid #f2f2ff;
    height: 30px;
    border-radius: 4px;
}

.filters-container > select {
    border: 2px solid #f2f2ff;
    height: 30px;
    border-radius: 4px;
}
