@use "styles/base";

.tabs__container {
    //border: 1px solid #005A29;//TODO: border-test
    width: 100%;

    & .tab-buttons {
        padding-left: 16px;
        display: flex;
        flex-direction: row;
        column-gap: 28px;
        height: 45px;

        & .left-divider {
            width: 6px; //16 including the column gap
            height: 1px;
        }

        & button {
            height: 36px;
            background-color: transparent;
            border: none;

            cursor: pointer;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: base.$color-N250;

            transition: all ease-in-out 0.2s;
        }

        & .active {
            color: base.$color-P300;
        }
    }

    .tab-divider {
        height: 1px;
        background-color: base.$color-N200;
        //margin-bottom: 56px;
    }
}
