.header {
    position: sticky;
    background-color: #ffffff;
    top: 0; /* Position the header at the top of the page */
    width: 100%; /* Full width */
    z-index: 9990;
    border-bottom: 1px solid #cecedc;
}

.no-header {
    display: none;
}

.nav-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 70px;
}

.nav-logo {
    width: 140px;
    height: 32px;
    /* top | right | bottom | left */
    margin: 23.5px 60px 13.5px 46px;
    background: url("assets/images/logo.png");
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin-right: 30px;
    margin-left: 20px;
}

.nav-item {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.nav-item.nav-item--help {
    background: url("assets/images/spritesheet.png") no-repeat -60px -400px;
}
.nav-item.nav-item--settings {
    background: url("assets/images/spritesheet.png") no-repeat -1px -400px;
}
.nav-item.nav-item--divider {
    width: 1px;
    height: 37px;
    /* top | right | bottom | left */
    margin: 16.5px 20px 15.5px 10px;
    border-right: #cecedc solid 1px;
    cursor: default;
}
.nav-item.nav-item--avatar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: #dadada;
}
.nav-item.nav-item--avatar img {
    cursor: default;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.nav-item.nav-item--avatar svg {
    margin: 0 10px;
}

.ant-dropdown {
    z-index: 10000;
}

