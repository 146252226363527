.mini-progress-bar {
    /* place in middle of screen */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* set width and height */

    border-radius: 15px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
    z-index: 99;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    color: #858585;
    background-color: #fbfbff;
    border: none;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f4eaff;
}
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #ead9fc;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #b46bff;
    border-color: #b46bff;
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #b46bff;
}
/* hover */
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #b46bff;
}
.ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #b46bff;
    border-color: #b46bff;
}
