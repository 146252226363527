.lead-button {
    padding: 20px;
    height: 100%;
    width: 389px;
    background: #fbfbff;
    border: 1px solid #f2f2ff;
    border-radius: 6px;
    cursor: pointer;
}
.lead-button:hover {
    border: 1px solid #7b31c6;
    background: #f2f2ff;
}
.lead-button .lead-button-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}
.lead-button .lead-button-content p.samplead-body--large {
    color: #353535;
}
.lead-button .lead-button-content p.samplead-body {
    width: 280px;
    color: #535353;
}

.lead-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
