.sidebar {
    position: fixed;
    height: 100%;
    width: 100px;
    top: 70px;
    overflow-x: hidden;
    background-color: #f2f2ff;
}

.no-sidebar {
    display: none;
}

.sidebar.left {
    left: 0;
}
.sidebar.right {
    right: 0;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    row-gap: 16px;
    margin: 62px 0;
}

.sidebar__link {
    width: 100px;
    height: 55px;
    align-self: center;
}
.sidebar__link.active {
    width: 100px;
    height: 55px;
}
.sidebar__link.dashboard {
    background: url("assets/images/sidebar/dashboard.png") no-repeat;
    background-position: center center;
}
.sidebar__link.dashboard.active {
    background: url("assets/images/sidebar/dashboard_active.png") no-repeat;
    background-position: center center;
}
.sidebar__link.magic_moments {
    background: url("assets/images/sidebar/magic_moments.png") no-repeat;
    background-position: center center;
}
.sidebar__link.magic_moments.active {
    background: url("assets/images/sidebar/magic_moments_active.png") no-repeat;
    background-position: center center;
}
.sidebar__link.statistics {
    background: url("assets/images/sidebar/statistics.png") no-repeat;
    background-position: center center;
}
.sidebar__link.statistics.active {
    background: url("assets/images/sidebar/statistics_active.png") no-repeat;
    background-position: center center;
}
.sidebar__link.embedded {
    background: url("assets/images/sidebar/embedded.png") no-repeat;
    background-position: center center;
}
.sidebar__link.embedded.active {
    background: url("assets/images/sidebar/embedded_active.png") no-repeat;
    background-position: center center;
}

.sidebar__link.replies {
    background: url("assets/images/sidebar/replies.png") no-repeat;
    background-position: center center;
}
.sidebar__link.replies.active {
    background: url("assets/images/sidebar/replies_active.png") no-repeat;
    background-position: center center;
}

.sidebar__link.samplead_automation {
    background: url("assets/images/sidebar/samplead_automation.png") no-repeat;
    background-position: center center;
}

.tooltip-style {
    font-size: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
    max-width: 100px;
}

