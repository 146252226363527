body {
    height: 100%;
    width: 100%;
}

.App {
    position: relative;
    overflow-x: hidden;
    border-width: 0 1px;
    border-color: black;
    border-style: dashed;
}

.main-logo {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 200px;
    width: 20vw;
}

.app-notify {
    position: absolute;
    width: 450px;
    background-color: #00000073;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 50px;
    text-align: center;
}

.app-bg-top {
    width: 100%;
}

.main {
    width: 450px;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.main-bg {
    width: 100%;
    overflow-x: hidden;
}

.joinedPopup {
    width: 300px;
    height: 100px;
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d202296;
    border-radius: 50px;
    text-align: center;
}

input {
    outline: none;
}

textarea {
    outline: none;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
