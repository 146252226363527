div.popup {
    min-width: 555px;
    min-height: 180px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
div.popup > div.popup__title {
    display: flex;
    justify-content: space-between;
}
div.popup > div.popup__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

