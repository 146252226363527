.activity-icon {
    width: 37.25px;
    height: 40px;

    &.event {
        background: url("assets/images/spritesheet.png") no-repeat -120px -20px;
    }

    &.group {
        background: url("assets/images/spritesheet.png") no-repeat -120px -80px;
    }

    &.post {
        background: url("assets/images/spritesheet.png") no-repeat -120px -140px;
    }
}

.icon {
    width: 48px;
    height: 48px;

    &.auto-discovery {
        background: url("assets/images/spritesheet.png") no-repeat -120px -200px;
    }

    &.message-typing {
        background: url("assets/images/spritesheet.png") no-repeat -120px -260px;
    }

    &.export {
        background: url("assets/images/spritesheet.png") no-repeat -120px -320px;
    }

    &.manual {
        background: url("assets/images/spritesheet.png") no-repeat -120px -380px;
    }

    &.empty {
        background: url("assets/images/spritesheet.png") no-repeat -120px -440px;
    }

    &.hot {
        width: 35px;
        height: 57px;
        background: url("assets/images/spritesheet.png") -20px -320px;
        //transform: scale(0.85);
    }

    &.close {
        width: 10px;
        height: 10px;
        background: url("assets/images/spritesheet.png") no-repeat -60px -240px;
    }

    &.empty-stats {
        background: url("assets/images/spritesheet.png") no-repeat -120px -500px;
        width: 50px;
        height: 51px;
    }

    &.life-server-ring {
        background: url("assets/images/spritesheet.png") no-repeat -200px -220px;
        width: 53px;
        height: 53px;
    }
}
