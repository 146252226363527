.main-content {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 80px;
    gap: 25px;
}

.header-container {
    /* Auto layout */
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
}

.page-title {
    left: calc(50% - 125px / 2 - 529.5px);
    top: calc(50% - 50px / 2);

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;

    /* Naturals / N500 */

    color: #353535;
}

.settings-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.edit-field-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    gap: 40px;
    width: 100%;
    min-height: 100%;
    /* Inside auto layout */
    grid-gap: 5px;
    column-gap: 20px !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.edit-field-container > .input-form-group {
    width: 47%;
}

.edit-field-container .input-form-group .input-container {
    width: 100%;
}

.edit-field-container-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    gap: 40px;
    width: 50%;
    min-height: 100%;
    /* Inside auto layout */
    grid-gap: 5px;
    column-gap: 20px !important;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.edit-field-container-2 > .input-form-group {
    width: 100%;
}

.edit-field-container-2 .input-form-group .input-container {
    width: 100%;
}

.input-wrapper {
    width: 50%;
}

.input-header {
    width: 100%;
}

.input-form-box {
    width: 100%;
}

.input-container {
    width: 48%;
}

.pagify-container {
    width: 100%;
}

.pagify-arrows {
    cursor: pointer;
}

.pagify-arrows:hover {
    text-decoration: underline;
}

.fields-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 100%;
    min-width: 1184px;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.checkbox-container {
    width: 40%;
    order: 3;
}

.checkbox-header-wrapper {
    width: 100%;
}

.checkbox-header-wrapper-text {
    height: 17px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */

    /* Naturals / N500 */

    color: #353535;
}

.checkbox-input-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}

.checkbox-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 120px;
    white-space: nowrap;
    column-gap: 10px;
}

.uploaded_date_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: "tnum" on, "lnum" on;

    color: #c9b4b4;
}

.upload-file-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.file-uploader-box-dark {
    display: flex;
    color: #0658c2 !important;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px;
    gap: 18px;
    color: #0658c2;
    /*  Add dotted #0658c2; border */
    border-color: #0658c2 !important;
    /*  width: 543px; */
    height: 100px !important;
    max-width: none !important;
}

.file-uploader-box-dark > svg {
    display: none;
}

.file-uploader-box-dark > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px;
}

.file-uploader-box-dark > div > span {
    color: #0658c2 !important;
    font-size: 15px;
}

.file-uploader-box-dark > div > span:last-child {
    display: none;
}

.uploaded_file_item__name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.delete-file-button {
    padding-left: 10px;
    font-size: 15px;
}
.delete-file-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1300px) {
    .fields-container {
        flex-direction: column;
        width: 100%;
        min-width: 0;
    }

    .edit-field-container > .input-form-group {
        width: 100%;
    }

    .edit-field-container-2 {
        width: 100%;
    }

    .edit-field-container-2 > .input-form-group {
        width: 100%;
    }
}
