@import "styles/base";

.sidebar {
    position: fixed;
    height: 100%;
    width: $sidebar-width;
    //z-index: 1;
    top: $top-nav-height;
    overflow-x: hidden;
    background-color: $color-N100;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    row-gap: 16px;
    margin: 62px 0;
}

.sidebar__link {
    width: 24px;
    height: 22px;
    background-color: #7a7a7a;
    align-self: center;

    &.active {
        width: 100px;
        height: 55px;
    }

    &.dashboard {
        background: url("assets/images/spritesheet.png") no-repeat -20px -200px;

        &.active {
            background: url("assets/images/spritesheet.png") no-repeat -180px -60px;
        }
    }

    &.campaigns {
        background: url("assets/images/spritesheet.png") no-repeat -20px -240px;

        &.active {
            background: url("assets/images/spritesheet.png") no-repeat -180px -120px;
        }
    }

    &.discovery {
        background: url("assets/images/spritesheet.png") no-repeat -60px -200px;

        &.active {
            background: url("assets/images/spritesheet.png") no-repeat -180px -120px;
        }
    }
}
