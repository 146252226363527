@import "styles/base";
//outline: 1px solid green;//TODO: border-test
//& > * {
//  outline: 1px solid #07c3d7; //TODO: outline-test;
//}

$campaigns-container-width: 100%;
$campaigns__main-height: 582px;

.campaigns__container {
    padding: 80px 78px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: $gap-20;

    &.no-tabs {
        //height: 792px; //750 + gap
        row-gap: $gap-32;
    }
}

.campaigns__snackbar {
    position: absolute;
    top: 96px; //navbar height + 24px
}

.campaigns__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 81px;
    min-width: $campaigns__main--width;

    & .campaigns__header-top {
        align-self: flex-start;
        display: flex;
        color: $color-black;

        & a {
            text-decoration: none;
        }
    }

    & .campaigns__header-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-black;
        height: 50px;
        width: 100%;

        & > .campaigns__header-bottom-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: $gap-10;

            & .campaigns__header-title {
                max-width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            & > .campaigns__header-leads-count {
                color: rgba(122, 122, 122, 0.5);
                margin-left: 4px;
            }
        }

        & .campaigns__header-bottom-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: $gap-10;

            & .button {
                font-weight: 500;
                line-height: 20px;
                font-size: 14px;
            }

            & .button--link {
                margin-right: 12.58px;
            }
        }
    }
}

.campaigns__main-top {
    display: flex;
    flex-direction: column;
    row-gap: $gap-20;
    margin: 20px 0px;

    & > * {
        display: flex;
        align-items: center;
    }

    & > .campaigns__main-top-options {
        height: 36px;
        justify-content: space-between;

        & > .samplead-body {
            color: $color-N500;
        }

        & > .campaigns__main-top-options--buttons {
            display: flex;
            column-gap: $gap-10;
        }
    }

    & > .campaigns__main-top-headers {
        height: 26px;

        & > .samplead-body {
            color: $color-N300;

            &:nth-child(1) {
                margin-left: 33px;
            }

            &:nth-child(2) {
                margin-left: 193px;
            }

            &:nth-child(3) {
                margin-left: 240px;
            }
            &:nth-child(4) {
                margin-left: 240px;
            }
        }

        &.manual {
            & > .samplead-body {
                &:nth-child(2) {
                    margin-left: 223px;
                }

                &:nth-child(3) {
                    margin-left: 272px;
                }
            }
        }
    }
}

.campaigns__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: $campaigns__main--width;
    height: $campaigns__main-height;

    //outline: 1px solid #07c3d7; //TODO: outline-test;
    //& > * {
    //  outline: 1px solid red; //TODO: outline-test;
    //}

    background-color: $color-white;
    border-radius: $radius-6;

    &.no-tabs {
        height: 582px;
        padding: 143.5px 351.5px 143.5px 374.5px;
    }

    &.empty-tab {
        height: 444px;
        //padding: 153px 163.5px;
    }

    &.creating-lead-auto {
        height: 444px;
        padding: 142.5px 374.5px 142.5px 351.5px;
        margin-top: 56px;
    }

    &.show-lead-list {
        height: 454px;
    }

    & .campaigns__main-content {
        //& > * {
        //  outline: 1px solid green; //TODO: outline-test;
        //}

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 389px;
        height: 390px;

        &.creating-lead-auto {
            width: 458px;
            justify-content: flex-start;
            row-gap: 18px;

            & .samplead-body {
                color: $color-N400;
            }

            & .leads-caption {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: $gap-10;

                color: $color-N400;
            }
        }

        &.no-tabs {
            width: 857px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            & p.samplead-body {
                color: $color-dark-gray;
            }

            & > .campaigns__main-content--no-tabs-options {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: $gap-10;

                & > .campaigns__main-content--no-tabs-options-center {
                    width: 39px;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 10px;

                    & > .campaigns__main-content--no-tabs-options-divider {
                        width: 1px;
                        height: 76px;
                        background: $color-P300;
                    }
                }
            }
        }

        &.empty-tab {
            width: 458px;
            height: fit-content;
            gap: 18px;

            & > p.samplead-body {
                color: $color-N400;
            }

            & > .leads-caption {
                display: flex;
                align-items: center;
                column-gap: $gap-10;
                color: $color-N300;
            }
        }

        &.show-lead-list {
            width: 100%;
            height: 100%;
            row-gap: $gap-10;
        }

        &.progress-bar {
            width: 588px;
            height: 54px;
        }
    }
}
