.tabs__container {
    width: 100%;
    padding-top: 32px;
}
.tabs__container .tab-buttons {
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    column-gap: 28px;
    height: 45px;
}
.tabs__container .tab-buttons .left-divider {
    width: 6px;
    height: 1px;
}
.tabs__container .tab-buttons button {
    height: 36px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #a7a7a7;
    transition: all ease-in-out 0.2s;
}
.tabs__container .tab-buttons .active {
    color: #a54cff;
}
.tabs__container .tab-divider {
    height: 1px;
    background-color: #dadada;
}

